import { Container } from "@sop/ui-library";
import styled from "styled-components";

export const RightSection = styled(Container)`
  && {
    height: fit-content;
    @media (min-width: 1365px) {
      grid-column: 9 / span 4;
    }

    @media (max-width: 768px) {
      gap: 1rem;
    }
  }
`;

export const LeftSection = styled(Container)`
  && {
    height: fit-content;
    @media (min-width: 1365px) {
      grid-column: 1 / span 8;
    }

    @media (max-width: 768px) {
      gap: 1rem;
      padding-bottom: 1rem;
    }
  }
`;
