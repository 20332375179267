import PrivateInsurance from "./PrivateInsurance";
import { usePermissions } from "../../security";
import { Banner, Container, Card, FactBox, Link, Typography } from "@sop/ui-library";
import { getUserInformation } from "../../shared/services/unifyService";
import { useEffect, useState } from "react";
import { UserInformation } from "../../shared/interfaces";
import { useT } from "../../shared/internationalization";
import PensionPrognosisGraph from "./PensionPrognosisGraph/PensionPrognosisGraph";
import ContactInformationDialog from "./ContactInformationDialog";
import { useQuery } from "@tanstack/react-query";
import { getAccountInformation, getSpAgreementVersion, getSpPlusAgreementFile } from "../../shared/services/spPlusService";
import Footer from "../Footer/Footer";
import MyServices from "../MyServices";
import WealthSection from "./Wealth/WealthSection";
import { LeftSection, RightSection } from "./Plus.styled";
import { downloadFile, redirectUrls } from "../../shared/utils";
import { Heading, Links, StyledDocumentIcon, StyledLink, StyledLinkIcon } from "../MyInformation/MyInformation.styled";
import Snackbar, { SnackBarState } from "./Snackbar";

export default function Plus() {
  const permissions = usePermissions();
  const phrases = useT("SpPlus");
  const factBoxSectionPhrases = useT("FactBoxSection");
  const [user, setUser] = useState<UserInformation | undefined>(undefined);
  const [snackbarState, setSnackbarState] = useState<SnackBarState>({
    open: false,
    severity: "info",
    message: "",
    alertTitle: "",
  });

  const { alertTitle, message, open, severity } = snackbarState;

  const handleCloseSnackbar = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ ...snackbarState, open: false });
  };

  const { data, isPending } = useQuery({
    queryKey: ["getAccountInformation"],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const result = await getAccountInformation();
      return result;
    },
    enabled: true,
  });

  const { data: agreementVersion, isPending: agreementVersionIsPending } = useQuery({
    queryKey: ["getSpAgreementVersion"],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const result = await getSpAgreementVersion();
      return result;
    },
    enabled: true,
  });

  const userEmail = data?.email;
  const userPhone = data?.phoneNumber;

  useEffect(() => {
    async function getUser() {
      const user = await getUserInformation();
      setUser(user);
    }
    getUser();
  }, []);

  if (user === undefined) return null;

  async function fetchSpPlusAgreementDocument() {
    try {
      const response = await getSpPlusAgreementFile();
      if (!response.ok) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: factBoxSectionPhrases("SpPlusAgreementSnackbarErrorMessage"),
          alertTitle: factBoxSectionPhrases(
            "SpPlusAgreementSnackbarErrorTitle"
          ),
        });
      } else {
        await downloadFile(
          response,
          `${factBoxSectionPhrases("SpPlusAgreementDocumentText")}.pdf`
        );
        setSnackbarState({
          open: true,
          severity: "success",
          message: factBoxSectionPhrases(
            "SpPlusAgreementSnackbarSuccessMessage"
          ),
          alertTitle: factBoxSectionPhrases(
            "SpPlusAgreementSnackbarSuccessTitle"
          ),
        });
      }
    } catch {
      setSnackbarState({
        open: true,
        severity: "error",
        message: factBoxSectionPhrases("SpPlusAgreementSnackbarErrorMessage"),
        alertTitle: factBoxSectionPhrases("SpPlusAgreementSnackbarErrorTitle"),
      });
    }
  }

  return (
    <>
      {!isPending && (!userPhone || !userEmail) && (
        <ContactInformationDialog
          userPhoneNumber={userPhone}
          userEmail={userEmail}
        />
      )}
      <Snackbar
        open={open}
        severity={severity}
        message={message}
        alertTitle={alertTitle}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Banner
        contentTheme="white"
        heading={phrases("BannerTitle").replace("__NAME__", user?.fullName)}
        target="_blank"
        text={phrases("BannerDescription")}
        theme="black"
        backgroundImage={{
          url: "/images/spplus.svg",
          overlay: "UpRight",
          darken: true,
        }}
        transparentMenuGap={true}
      />
      <Container theme="lightgray">
        <LeftSection hasPadding={false}>
          {(permissions.isWealthCustomer ||
            permissions.isPensionAdvisoryCustomer) && <WealthSection />}
          {permissions.isPensionCustomer && <PensionPrognosisGraph />}
          {permissions.isPrivateInsuranceUsers && <PrivateInsurance />}
        </LeftSection>
        <RightSection hasPadding={false}>
          <FactBox
            key={"factbox-my-profile"}
            anchorName="factbox-my-profile"
            heading={factBoxSectionPhrases("MyProfile")}
            links={[
              {
                title: factBoxSectionPhrases("MyProfile"),
                url: redirectUrls.informationCollectionBaseUrl,
              },
            ]}
          >
            <p>{factBoxSectionPhrases("MyProfileDescription")}</p>
          </FactBox>
          <Card theme="white">
            <Heading>{factBoxSectionPhrases("MyDocuments")}</Heading>
            <Typography variant="body-m">{factBoxSectionPhrases("MyDocumentsDescription")}</Typography>
            <Links>
              <StyledLink
                id="spPlusDocument"
                key={"spPlusDocument"}
                hoverSpan={true}
                underline="none"
                onClick={(e) => {
                  fetchSpPlusAgreementDocument();
                  e.preventDefault();
                }}
              >
                <StyledDocumentIcon aria-hidden="true" />
                <span>{factBoxSectionPhrases("SpPlusAgreementDocumentText")}</span>
              </StyledLink>
              <StyledLink
                to={`${redirectUrls.pensionBaseUrl}/MeetingDocuments/MyDocuments`}
                id="MyDocumentsLink"
                key={"MyDocumentsLink"}
                target="_blank"
                hoverSpan={true}
                underline="none"
              >
                <StyledLinkIcon aria-hidden="true" />
                <span>{factBoxSectionPhrases("MyDocuments")}</span>
              </StyledLink>
            </Links>
          </Card>
          <FactBox
            key={"factbox-my-cases"}
            anchorName="factbox-my-cases"
            heading={factBoxSectionPhrases("MyCases")}
            links={[
              {
                title: factBoxSectionPhrases("MyCases"),
                url: redirectUrls.myCasesBaseUrl,
              },
            ]}
          >
            <p>{factBoxSectionPhrases("MyCasesDescription")}</p>
          </FactBox>
          {!agreementVersionIsPending && agreementVersion === 1 &&<Card theme="white">
            <Heading>{factBoxSectionPhrases("NewAgreementTitle")}</Heading>
            <p><Typography variant="label-s" style={{ color: "var(--color-black-o68)" }}>{factBoxSectionPhrases("NewAgreementDate")}</Typography></p>
            <Typography variant="body-m">{factBoxSectionPhrases("NewAgreementFirstDescription")}<br />
              {factBoxSectionPhrases("NewAgreementSecondDescription")}<Link url={factBoxSectionPhrases("NewAgreementLinkUrl")} target="_blank">{factBoxSectionPhrases("NewAgreementLinkText")}</Link>{factBoxSectionPhrases("NewAgreementThirdDescription")}</Typography>
          </Card>}
        </RightSection>
      </Container>
      <MyServices />
      <Footer isSpPlus />
    </>
  );
}
