import { Container, FactBox, FactBoxSection } from "@sop/ui-library";
import { useT } from "../../shared/internationalization";
import { redirectUrls } from "../../shared/utils";

export default function MyInformation() {
  const factBoxSectionPhrases = useT("FactBoxSection");

  const factBoxSectionArgs = {
    theme: "lightgray" as const,
    title: factBoxSectionPhrases("Title"),
    children: [
      <FactBox
        key={"factbox-my-profile"}
        anchorName="factbox-my-profile"
        heading={factBoxSectionPhrases("MyProfile")}
        links={[
          {
            title: factBoxSectionPhrases("MyProfile"),
            url: redirectUrls.informationCollectionBaseUrl,
          },
        ]}
      >
        <p>{factBoxSectionPhrases("MyProfileDescription")}</p>
      </FactBox>,
      <FactBox
        key={"factbox-my-documents"}
        anchorName="factbox-my-documents"
        heading={factBoxSectionPhrases("MyDocuments")}
        links={[
          {
            title: factBoxSectionPhrases("MyDocuments"),
            url: `${redirectUrls.pensionBaseUrl}/MeetingDocuments/MyDocuments`,
          },
        ]}
      >
        <p>{factBoxSectionPhrases("MyDocumentsDescription")}</p>
      </FactBox>,
      <FactBox
        key={"factbox-my-cases"}
        anchorName="factbox-my-cases"
        heading={factBoxSectionPhrases("MyCases")}
        links={[
          {
            title: factBoxSectionPhrases("MyCases"),
            url: redirectUrls.myCasesBaseUrl,
          },
        ]}
      >
        <p>{factBoxSectionPhrases("MyCasesDescription")}</p>
      </FactBox>,
    ],
  };

  return (
    <>
      <Container theme={factBoxSectionArgs.theme}>
        <FactBoxSection {...factBoxSectionArgs} />
      </Container>
    </>
  );
}
